/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable wrap-rem-on-px/wrap-rem-on-px */
/* stylelint-enable wrap-rem-on-px/wrap-rem-on-px */
/*
USAGE:
font-size: fluid(12, 62); : from rem(12)@375px to  rem(62)@1440px
gap: fluid(10, 33, 992);  : from rem(10)@991px to  rem(33)@1440px
margin-right: fluid(32, 65, 320, 2560);  : from rem(32)@320px to  rem(65)@2560px
*/
/* stylelint-disable */
/* stylelint-enable */
/* Font */
/* Base palette */
/* Brand palette */
/* Theme */
/* State palette */
.section--serial-tag {
  padding: 6rem 0 clamp(2rem, -3.00752vw + 2.706766875rem, 0rem);
}
.section--serial-tag-grey {
  background-color: #DCDCDC;
}
.section--serial-tag-grey .qpac.serial-tag {
  border-color: #1C1D21;
  color: #1C1D21;
}
.section--serial-tag-grey .qpac.serial-tag .serial-tag__title {
  border-left-color: #1C1D21;
}

.qpac.serial-tag {
  display: inline-flex;
  border: 0.0625rem solid #DCDCDC;
}
.qpac.serial-tag .serial-tag__no {
  padding: 0.4375rem 0.5625rem 0.4375rem 0.4375rem;
  font-size: 0.75rem;
  line-height: 120%;
  text-transform: uppercase;
}
@media screen and (min-width:991px) {
  .qpac.serial-tag .serial-tag__no {
    padding-bottom: 0.375rem;
  }
}
.qpac.serial-tag .serial-tag__title {
  display: flex;
  flex-direction: row;
  padding: 0.4375rem 1.4375rem 0.4375rem 0.4375rem;
  border-left: 0.0625rem solid #DCDCDC;
  gap: 0.5rem;
}
@media screen and (min-width:991px) {
  .qpac.serial-tag .serial-tag__title {
    padding-bottom: 0.375rem;
  }
}
.qpac.serial-tag .serial-tag__title h2 {
  margin: 0;
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 120%;
  text-transform: uppercase;
}
.qpac.serial-tag .serial-tag__title span {
  font-size: 0.75rem;
  line-height: 120%;
}
.qpac.serial-tag.black-variant {
  border: 0.0625rem solid #1C1D21;
  color: #1C1D21;
}
.qpac.serial-tag.black-variant .serial-tag__title {
  border-left: 0.0625rem solid #1C1D21;
}

.section--slider {
  padding: 5.9375rem 0 2.4375rem;
  background-color: #DCDCDC;
}
@media screen and (min-width:768px) {
  .section--slider {
    padding: 5.9375rem 0 7.75rem;
  }
}
.section--slider .container {
  padding-right: 0;
}

.qpac.slider {
  margin: 3.5rem 0;
}
.qpac.slider .slick-slide > div {
  margin-right: clamp(1rem, -0.39063vw + 1.1875rem, 0.9375rem);
}
.qpac.slider .slider__item {
  position: relative;
  cursor: default;
}
.qpac.slider .slider__item h3, .qpac.slider .slider__item p {
  color: #1C1D21;
  font-weight: 400;
  text-transform: uppercase;
}
.qpac.slider .slider__item h3 {
  margin-bottom: 0.5rem;
  font-size: clamp(0.8125rem, 0.65789vw + 0.657895rem, 1.25rem);
  line-height: 120%;
}
.qpac.slider .slider__item h3 span {
  margin-left: 1.25rem;
}
.qpac.slider .slider__item p {
  margin-top: 0.5rem;
  font-size: 0.875rem;
  letter-spacing: -0.0175rem;
  line-height: 135%;
}
.qpac.slider .slider__item .aspect-box {
  --aspect-ratio: 300 /300;
  overflow: hidden;
}
@media screen and (min-width:768px) {
  .qpac.slider .slider__item .aspect-box {
    --aspect-ratio: 574 /574;
  }
}
.qpac.slider .slider__item .aspect-box img {
  transform: scale(1);
  will-change: transform;
  transition: all 0.3s ease-in-out;
}
.qpac.slider .slider__item:hover .aspect-box img {
  transform: scale(1.02);
}
.qpac.slider .slick-arrow {
  position: absolute;
  z-index: 1;
  right: 1.0625rem;
  bottom: -19.5%;
  display: block;
  overflow: hidden;
  padding: 1.014375rem 1.2375rem 1.139375rem;
  border: 0.0625rem solid #242830;
  background-color: transparent;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}
.qpac.slider .slick-arrow::before {
  position: absolute;
  z-index: -1;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background-color: #00FFAE;
  content: "";
  transition: left 0.3s ease;
}
.qpac.slider .slick-arrow svg {
  position: relative;
  z-index: 2;
  width: 0.5rem;
  height: 0.3125rem;
  transition: all 0.3s ease-in-out;
}
.qpac.slider .slick-arrow.slick-prev {
  right: 4.4375rem;
}
.qpac.slider .slick-arrow:hover {
  border-color: #00FFAE;
}
.qpac.slider .slick-arrow:hover::before {
  left: 0;
}
.qpac.slider .slick-arrow[aria-disabled=true] {
  opacity: 0.5;
  pointer-events: none;
}